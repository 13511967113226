<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line mt-0"
         v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['consolidations_consolidationUSA', 'consolidations_consolidationEU'])"></div>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{active: item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_create'])"></div>
          <MainButton class="btn-right-block-i"
                      :value="$t('common_create.localization_value.value')"
                      :ico="'ico'"
                      @click.native="changeShippingCostChangePopup(true)"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus" />
            </template>
          </MainButton>
      </div>
    </div>

    <ShippingCostChangePopup
      v-if="isModalShippingCostChangePopup"
      :typePopup="'create'"
      :shippingType="activeTab"
      @close="changeShippingCostChangePopup(false)"
      @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import ShippingCostChangePopup from "../../../../popups/ShippingCostChangePopup/ShippingCostChangePopup";

  export default {
    name: "ShippingCostHead",
    components:{
      MainButton,
      PlusIconSVG,
      ShippingCostChangePopup,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data(){
      return{
        isModalShippingCostChangePopup: false,
        activeTab: {},
      }
    },

    methods: {
      changeShippingCostChangePopup(val){
        this.activeTab = this._.find(this.navTabs, {active: true})
        this.isModalShippingCostChangePopup = val
      }
    }

  }
</script>

<style scoped>

</style>

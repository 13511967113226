<template>

  <div class="site-table-mobile__item">
    <div class="site-table-mobile__row custom-row">
      <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column">
        {{$t('shippingCost_WeightKg.localization_value.value')}}
        <span class="mt-1">{{item.max_weight}}</span>
      </div>
      <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column"
                     v-if="checkShowCost('ukraine')"
      >
        {{$t('shippingCost_UkraineCost.localization_value.value')}}
        <span class="mt-1">${{item.price}}</span>
      </div>
<!--      <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column"-->
<!--                     v-if="checkShowCost('belarus')"-->
<!--      >-->
<!--        {{$t('shippingCost_BelarusCost.localization_value.value')}}-->
<!--        <span class="mt-1">${{item.price_country_group_by}}</span>-->
<!--      </div>-->
<!--      <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column"-->
<!--                     v-if="checkShowCost('russian')"-->
<!--      >-->
<!--        {{$t('shippingCost_RussiaCost.localization_value.value')}}-->
<!--        <span class="mt-1">${{item.price_country_group_sng}}</span>-->
<!--      </div>-->
<!--      <div class="site-table-mobile__piece custom-col custom-col&#45;&#45;50 d-flex flex-column"-->
<!--           v-if="checkShowCost('latvia')"-->
<!--      >-->
<!--        {{$t('shippingCost_LatviaCost.localization_value.value')}}-->
<!--        <span class="mt-1">${{item.price_country_group_latvia}}</span>-->
<!--      </div>-->
      <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column"
           v-if="checkShowCost('poland')"
      >
        {{$t('shippingCost_PolandCost.localization_value.value')}}
        <span class="mt-1">${{item.price_country_group_poland}}</span>
      </div>
      <div class="site-table-mobile__piece custom-col custom-col--50 d-flex flex-column"
           v-if="checkShowCost('uk')"
      >
        {{$t('shippingCost_UkCost.localization_value.value')}}
        <span class="mt-1">${{item.price_country_group_uk}}</span>
      </div>
    </div>
    <div class="site-table-mobile__row custom-row">
      <div class="site-table-mobile__piece custom-col custom-col--wfc">
        <LinkButton
                :value="$t('common_edit.localization_value.value')"
                :type="'edit'"
                @click.native="$emit('changeShippingCostChangePopup')"
        />
      </div>
      <div class="site-table-mobile__piece custom-col custom-col--wfc">
        <LinkButton
                :value="$t('common_delete.localization_value.value')"
                :type="'delete'"
                @click.native="$emit('removeItem')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import {shippingCost} from "../../../../../../../../mixins/shippingCost/shippingCost";

  export default {
    name: "ShippingCostTableMobile",
    components: {LinkButton},

    mixins: [shippingCost],

    props: {
      shippingType: Object,
      item: Object,
    },
  }
</script>

<style scoped>

</style>

<template>
  <ShippingCostTableAdmin
          :navTabs="navTabs"
          :countFilterParams="countFilterParams"
          :filterGetParams="filterGetParams"
          @changeTab="changeTab"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @reload="reload"
  />
</template>

<script>
  import ShippingCostTableAdmin from "./ShippingCostTableAdmin/ShippingCostTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {
    SHIPPING_COST_DELIVERY_SERVICES, LODZ_WAREHOUSE,
    //CAMBRIDGE_WAREHOUSE
  } from "../../../../../staticData/staticVariables";

  export default {
    name: "ShippingCostTable",
    components: {
      ShippingCostTableAdmin,
    },

    mixins: [routeFilter],

    data() {
      return {
        forPage: this.$store.getters.getShippingCostForPage,
        page: 1,
        onePage: 1,
        filterType: SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name,

        countFilterParams: 0,
        filterGetParams: {},

        navTabs: {
          [SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name]: {
            name: SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name,
            label: this.$t('consolidations_USADHL.localization_value.value'),
            id: SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id,
            active: true
          },
          // [SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name]: {
          //   name: SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.name,
          //   label: SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.label,
          //   id: SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id,
          //   active: false
          // },
          [SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name]: {
            name: SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name,
            label: this.$t('consolidations_consolidationUSA.localization_value.value'),
            id: SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id,
            active: false
          },
          // [SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name]: {
          //   name: SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.name,
          //   label: SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.label,
          //   id: SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.id,
          //   active: false
          // },
          [SHIPPING_COST_DELIVERY_SERVICES.DPD_DELIVERY_SERVICE.name]: {
            name: SHIPPING_COST_DELIVERY_SERVICES.DPD_DELIVERY_SERVICE.name,
            label: this.$t('consolidations_consolidationEU.localization_value.value'),
            id: SHIPPING_COST_DELIVERY_SERVICES.DPD_DELIVERY_SERVICE.id,
            active: false
          },
          [SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.name + '_' + LODZ_WAREHOUSE.name]: {
            name: SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.name + '_' + LODZ_WAREHOUSE.name,
            label: this.$t('consolidations_consolidationEUProductLodz.localization_value.value'),
            id: SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.id,
            storage_id: LODZ_WAREHOUSE.storage_id,
            active: false
          },
          // [SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.name + '_' + CAMBRIDGE_WAREHOUSE.name]: {
          //   name: SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.name + '_' + CAMBRIDGE_WAREHOUSE.name,
          //   label: this.$t('consolidations_consolidationEUProductCambridge.localization_value.value'),
          //   id: SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.id,
          //   storage_id: CAMBRIDGE_WAREHOUSE.storage_id,
          //   active: false
          // },
        },

      }
    },

    mounted() {

      if(this.$route.query.type !== undefined){
        this.navTabs[SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.name].active = false
        this.navTabs[this.$route.query.type].active = true
      }

      this.filter()

    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          type: this.$route.query.type,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)

        // Generate filter URL
        url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
        )

        this.$store.commit('setShippingCostFilter', window.location.search)

        if (next) this.$store.commit('setNextShippingCostPage', true)
        this.$store.dispatch('fetchShippingCost', url).then(() => {
          this.$store.commit('setNextShippingCostPage', false)
        })


        this.checkCountFilter(['type'])
      },

      generateFilterUrl(page, forPage) {
        let myQuery = this.createQueryBuilderObject()


        myQuery.where('delivery_service_id', this.navTabs[
          this.filterType !== '' ?
            this.filterType :
            SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.name
          ].id)

        if (this.filterType && this.navTabs[this.filterType]?.id === SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.id) {
          myQuery.where('storage_id', this.navTabs[this.filterType].storage_id)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

    },

  }
</script>

<style scoped>

</style>

<template>
  <div class="fragment">
    <ShippingCostHeadSearch/>

    <div class="detail-page">
      <div class="detail-page__left">
        <CardLeftBlock
            class="clear-992 height-search-engine"
            :backgroundImage="'product-admin'"
        >
          <template slot="body">
            <h2 class="mt-0 mb-1"
                v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <span class="admin-edit" @click="editTranslate(['shippingCost_ShippingCost'])"></span>
              {{$t('shippingCost_ShippingCost.localization_value.value')}}
            </h2>
          </template>
        </CardLeftBlock>
      </div>

      <div class="detail-page__right">


        <ShippingCostHead
                :navTabs="navTabs"
                :countFilterParams="countFilterParams"
                @changeTab="(data) => $emit('changeTab', data)"
                @reload="$emit('reload')"
        />

        <ShippingCostTable
                :countFilterParams="countFilterParams"
                :navTabs="navTabs"
                @showMore="$emit('showMore')"
                @resetFilter="$emit('resetFilter')"
                @reload="$emit('reload')"
        />

      </div>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import ShippingCostHeadSearch from "./ShippingCostHeadSearch/ShippingCostHeadSearch";
  import ShippingCostHead from "./ShippingCostHead/ShippingCostHead";
  import ShippingCostTable from "./ShippingCostTable/ShippingCostTable";
  export default {
    name: "ShippingCostTableAdmin",
    components: {
      CardLeftBlock,
      ShippingCostHeadSearch,
      ShippingCostHead,
      ShippingCostTable,
    },

    props: {
      navTabs: Object,
      countFilterParams: Number,
      filterGetParams: Object,
    },

    data(){
      return{

      }
    },

    methods: {

    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/mixins/mixins";
  @import "../../../../../../scss/colors";

  .tracking-pp{
    max-width: 400px;
    width: 100%;

    &__title{
      color: $black;
      font-weight: bold;
      line-height: normal;
      font-size: 24px;

      @include for-992{
        font-size: 24px;
        margin-bottom: 20px;
        line-height: normal;
      }
    }

    &__dp{
      border-radius: 6px;
      background: white;
    }
  }


</style>

<template>
  <div class="fragment">
    <div class="table-filter-wrap">
      <template v-if="$store.getters.getShippingCost.length > 0 && $store.getters.getShippingCostLoading !== true">
        <div class="site-table-wrap small-table"
             v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
            'shippingCost_WeightKg',
            'shippingCost_UkraineCost',
            'shippingCost_BelarusCost',
            'shippingCost_RussiaCost',
            // 'shippingCost_LatviaCost',
            'shippingCost_PolandCost',
            'shippingCost_UkCost',
            'common_edit',
            'common_delete',
          ])"></div>
          <table class="site-table mt-0" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th>{{$t('shippingCost_WeightKg.localization_value.value')}}</th>
              <th class="text-right"
                     v-if="checkShowCost('ukraine')"
              >
                {{$t('shippingCost_UkraineCost.localization_value.value')}}</th>
<!--              <th class="text-right"-->
<!--                     v-if="checkShowCost('belarus')"-->
<!--              >-->
<!--                {{$t('shippingCost_BelarusCost.localization_value.value')}}</th>-->
<!--              <th class="text-right"-->
<!--                     v-if="checkShowCost('russian')"-->
<!--              >-->
<!--                {{$t('shippingCost_RussiaCost.localization_value.value')}}</th>-->
<!--              <th class="text-right"-->
<!--                  v-if="checkShowCost('latvia')">{{$t('shippingCost_LatviaCost.localization_value.value')}}</th>-->
              <th class="text-right"
                  v-if="checkShowCost('poland')">{{$t('shippingCost_PolandCost.localization_value.value')}}</th>
              <th class="text-right"
                  v-if="checkShowCost('uk')">{{$t('shippingCost_UkCost.localization_value.value')}}</th>
              <th width="100%"></th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in $store.getters.getShippingCost" :key="index">
              <td>
                {{item.max_weight}}
              </td>
              <td align="right"
                     v-if="checkShowCost('ukraine')"
              >
                ${{item.price}}
              </td>
<!--              <td align="right"-->
<!--                     v-if="checkShowCost('belarus')"-->
<!--              >-->
<!--                ${{item.price_country_group_by}}-->
<!--              </td>-->
<!--              <td align="right"-->
<!--                     v-if="checkShowCost('russian')"-->
<!--              >-->
<!--                ${{item.price_country_group_sng}}-->
<!--              </td>-->
<!--              <td align="right"-->
<!--                  v-if="checkShowCost('latvia')"-->
<!--              >-->
<!--                ${{item.price_country_group_latvia}}-->
<!--              </td>-->
              <td align="right"
                  v-if="checkShowCost('poland')"
              >
                ${{item.price_country_group_poland}}
              </td>
              <td align="right"
                  v-if="checkShowCost('uk')"
              >
                ${{item.price_country_group_uk}}
              </td>
              <td></td>
              <td>
                <div class="table-right table-manage-list justify-content-end manager-btn-wrap">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) "
                                 :key="$store.getters.getGlobalUpdateManagerButton"
                                 class="right"
                  >
                    <template slot="item">
                      <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              @click.native="changeShippingCostChangePopup(true, item.id)"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeItem(item.id, index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>


          <div class="site-table-mobile pt-3"
               v-if="isMobileFunc()"
          >
            <div class="site-table-mobile__inner custom-row">
              <div class="custom-col custom-col--50 custom-col--sm-100"
                   v-for="(item, index) in $store.getters.getShippingCost"
                   :key="index">
                <ShippingCostTableMobile
                        :shippingType="shippingType"
                        :item="item"
                        @changeShippingCostChangePopup="changeShippingCostChangePopup(true, item.id)"
                        @removeItem="removeItem(item.id, index)"
                />
              </div>
            </div>
          </div>


        </div>

        <div class="table-bottom-btn">
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getShippingCostCommonList.next_page_url !== null && $store.getters.getShippingCost.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextShippingCostPage}"
                    :count="$store.getters.getShippingCostCommonList.total - $store.getters.getShippingCostForPage < $store.getters.getShippingCostForPage ?
                  $store.getters.getShippingCostCommonList.total - $store.getters.getShippingCostForPage:
                  $store.getters.getShippingCostForPage"
            />
          </div>
        </div>

      </template>

      <template v-if="$store.getters.getShippingCostLoading === false && $store.getters.getShippingCost.length === 0">
        <NoResult
                :countFilterParams="countFilterParams"
                :title="$t('common_noDataYet.localization_value.value')"
                :titleWithFilter="$t('common_noResult.localization_value.value')"
                :text="$t('common_noDataYetTxt.localization_value.value')"
                :textWithFilter="$t('common_noResultSeems.localization_value.value')"
                @resetFilter="$emit('resetFilter')"
        />
      </template>

    </div>

    <ShippingCostChangePopup
            v-if="isModalShippingCostChangePopup"
            :typePopup="'edit'"
            :shippingType="activeTab"
            :itemId="itemId"
            @close="changeShippingCostChangePopup(false)"
            @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  import {mixinDetictingMobile} from "../../../../../../../mixins/mobileFunctions";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ShippingCostChangePopup from "../../../../popups/ShippingCostChangePopup/ShippingCostChangePopup";
  import {shippingCost} from "../../../../../../../mixins/shippingCost/shippingCost";
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import ShippingCostTableMobile from "./ShippingCostTableMobile/ShippingCostTableMobile";
  // import ShowMore from "../../../../../../coreComponents/ShowMore/ShowMore";
  // import ExportBtn from '../../../../../../coreComponents/Export/Export'

  export default {
    name: "ShippingCostTable",
    components: {
      NoResult,
      ShowMore,
      // ShowMore,
      // ExportBtn,
      ManagerButton,
      LinkButton,
      ShippingCostChangePopup,
      ShippingCostTableMobile,
    },

    mixins: [mixinDetictingMobile, shippingCost],

    props: {
      navTabs: Object,
      countFilterParams: Number,
    },

    watch: {
      shippingCostList() {
        this.shippingType = this._.find(this.navTabs, {active: true})
      },
    },

    computed: {
      shippingCostList(){
        return this.$store.getters.getShippingCost
      }
    },

    data() {
      return {
        show1: false,
        show2: false,
        show3: false,
        show4: false,

        itemId: -1,
        activeTab: {},
        shippingType: {},
        isModalShippingCostChangePopup: false
      }
    },

    mounted() {
      console.log(this._.find(this.navTabs, {active: true}));
      this.shippingType = this._.find(this.navTabs, {active: true})
    },

    methods: {
      changeShippingCostChangePopup(val, id) {
        this.activeTab = this._.find(this.navTabs, {active: true})
        this.isModalShippingCostChangePopup = val
        this.itemId = id
      },

      cantDeleteProduct() {
        let text = {
          title: 'Ooops',
          txt: 'Sorry, you cannot uninstall this product',
          no: 'Ok'
        }

        let confirm = () => {
          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      removeItem(id, index = false) {
        let text = {
          title: 'common_deleteConfirm',
          txt: 'common_deleteItemAsk',
          yes: 'common_confirmDelete',
          no: 'common_confirmNo'
        }


        let confirm = () => {

          this.$store.dispatch('deleteShippingCost', id).then((response) => {

            if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

            switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
              case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
                if (index !== false) {
                  this.$store.getters.getShippingCost.splice(index, 1)
                }
                // this.$emit('reload')

                this.openNotify('success', 'common_notificationRecordDeleted')
                break
              }
              /**
               * Validation Error
               */
              case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
                let errors = response.response.data.errors;
                this.notifyErrorHelper(errors)
                break
              }
              /**
               * Undefined Error
               */
              default: {
                this.openNotify('error', 'common_notificationUndefinedError')
              }
            }

          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }

  }
</script>

<style lang="scss" scoped>

  .site-table tbody td {
    padding-top: 14px;
    padding-bottom: 14px;
  }

  .site-table tbody th:last-child,
  .site-table tbody td:last-child {
    padding-right: 5px;
  }

  /deep/ .manager-btn-wrap .manager-btn{
    width: 300px;
  }

</style>

<template>
  <div class="fragment">
    <modal
            @close="$emit('close')"
            class="custom-popup-modal custom-popup shipping-company-popup"
    >
      <template slot="header">
        <div v-if="typePopup === 'create'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['shippingCost_NewShippingCost'])"></div>
          {{$t('shippingCost_NewShippingCost.localization_value.value')}}
        </div>
        <div v-if="typePopup === 'edit'"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['shippingCost_EditShippingCost'])"></div>
          {{$t('shippingCost_EditShippingCost.localization_value.value')}}
        </div>
      </template>
      <template slot="body">
        <div class="custom-popup__content">
          <div class="shipping-company-popup__body">
            <div class="shipping-company-popup__content">
              <!--<div class="section-label">-->
              <!--<span v-if="isDHLService">-->
                <!--{{SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.label}}-->
              <!--</span>-->
                <!--<span v-if="isLandmarkService">-->
                <!--{{SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.label}}-->
              <!--</span>-->
                <!--<span v-if="isTNTService">-->
                <!--{{SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.label}}-->
              <!--</span>-->
                <!--<span v-if="isFEDEXService">-->
                <!--{{SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.label}}-->
              <!--</span>-->
              <!--</div>-->

              <div class="custom-row mb-3">
                <div class="custom-col custom-col--50 custom-col--md-50 mb-3"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['shippingCost_WeightKg'])"></div>
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': SC.validation.weight}"
                          :error="SC.validation.weight"
                          :errorTxt="$t(`${SC.validationTranslate.weight}.localization_value.value`)"
                          :label="$t('shippingCost_WeightKg.localization_value.value')"
                          v-model="SC.data.weight"
                  />
                </div>
                <div class="custom-col custom-col--50 custom-col--md-50 mb-3"
                     v-if="checkShowCost('ukraine')"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['shippingCost_UkraineCost'])"></div>
                  <DefaultInput
                          v-bind:class="{'ui-no-valid': SC.validation.cost}"
                          :error="SC.validation.cost"
                          :errorTxt="$t(`${SC.validationTranslate.cost}.localization_value.value`)"
                          :label="$t('shippingCost_UkraineCost.localization_value.value')"
                          v-model="SC.data.cost"
                  />
                </div>
<!--                <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-50 mb-3"-->
<!--                     v-if="checkShowCost('belarus')"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_BelarusCost'])"></div>-->
<!--                  <DefaultInput-->
<!--                          v-bind:class="{'ui-no-valid': SC.validation.costBel}"-->
<!--                          :error="SC.validation.costBel"-->
<!--                          :errorTxt="$t(`${SC.validationTranslate.costBel}.localization_value.value`)"-->
<!--                          :label="$t('shippingCost_BelarusCost.localization_value.value')"-->
<!--                          v-model="SC.data.costBel"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-50 mb-3"-->
<!--                     v-if="checkShowCost('russian')"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_RussiaCost'])"></div>-->
<!--                  <DefaultInput-->
<!--                          v-bind:class="{'ui-no-valid': SC.validation.costRus}"-->
<!--                          :error="SC.validation.costRus"-->
<!--                          :errorTxt="$t(`${SC.validationTranslate.costRus}.localization_value.value`)"-->
<!--                          :label="$t('shippingCost_RussiaCost.localization_value.value')"-->
<!--                          v-model="SC.data.costRus"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="custom-col custom-col&#45;&#45;50 custom-col&#45;&#45;md-50 mb-3"-->
<!--                     v-if="checkShowCost('latvia')"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['shippingCost_LatviaCost'])"></div>-->
<!--                  <DefaultInput-->
<!--                      v-bind:class="{'ui-no-valid': SC.validation.costLat}"-->
<!--                      :error="SC.validation.costLat"-->
<!--                      :errorTxt="$t(`${SC.validationTranslate.costLat}.localization_value.value`)"-->
<!--                      :label="$t('shippingCost_LatviaCost.localization_value.value')"-->
<!--                      v-model="SC.data.costLat"-->
<!--                  />-->
<!--                </div>-->
                <div class="custom-col custom-col--50 custom-col--md-50 mb-3"
                     v-if="checkShowCost('latvia')"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['shippingCost_PolandCost'])"></div>
                  <DefaultInput
                      v-bind:class="{'ui-no-valid': SC.validation.costPL}"
                      :error="SC.validation.costLat"
                      :errorTxt="$t(`${SC.validationTranslate.costPL}.localization_value.value`)"
                      :label="$t('shippingCost_PolandCost.localization_value.value')"
                      v-model="SC.data.costPL"
                  />
                </div>
                <div class="custom-col custom-col--50 custom-col--md-50 mb-3"
                     v-if="checkShowCost('uk')"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['shippingCost_UkCost'])"></div>
                  <DefaultInput
                      v-bind:class="{'ui-no-valid': SC.validation.costUk}"
                      :error="SC.validation.costUk"
                      :errorTxt="$t(`${SC.validationTranslate.costUk}.localization_value.value`)"
                      :label="$t('shippingCost_UkCost.localization_value.value')"
                      v-model="SC.data.costUk"
                  />
                </div>
              </div>

<!--              <div class="custom-row">-->
<!--                <div class="custom-col"-->
<!--                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                  <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--                  <div class="site-link text-decoration-underline btn-style brown"-->
<!--                       @click="changeHistoryOfChanges(true)"-->
<!--                  >-->
<!--                    {{$t('common_CheckHistory.localization_value.value')}}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="custom-popup__btn align-items-center d-flex flex-wrap"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_update', 'common_create'])"></div>
          <MainButton
                  class="custom-popup__btn-i secondary"
                  :value="$t('common_cancel.localization_value.value')"
                  @click.native="$emit('close')"
          />
          <MainButton
                  class="custom-popup__btn-i "
                  :value="this.typePopup === 'edit' ? $t('common_update.localization_value.value') : $t('common_create.localization_value.value')"
                  @click.native="save"
          />
        </div>
      </template>
    </modal>



  </div>
</template>

<script>
  import Modal from "../../../../commonModals/Modal";
  import MainButton from "../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../UI/inputs/DefaultInput/DefaultInput";
  import {ShippingCost} from "../../models/ShippingCost";
  import {SHIPPING_COST_DELIVERY_SERVICES} from "../../../../../staticData/staticVariables";
  import {shippingCost} from "../../../../../mixins/shippingCost/shippingCost";


  export default {
    name: "ShippingCostChangePopup",
    components: {
      Modal,
      MainButton,
      DefaultInput,
    },

    mixins: [shippingCost],

    props: {
      typePopup: String,
      shippingType: Object,
      itemId: [String, Number],
    },

    data() {
      return {
        SHIPPING_COST_DELIVERY_SERVICES: SHIPPING_COST_DELIVERY_SERVICES,
        SC: new ShippingCost(),
        isModalHistoryOfChanges: false,
      }
    },

    mounted() {
      this.SC.setType(this.shippingType.id)
      this.SC.setId(this.itemId)

      if (this.shippingType.id === SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.id) {
        this.SC.setStorageId(this.shippingType.storage_id)
      }

      if(this.typePopup === 'edit'){
        this.$store.dispatch('getShippingCostItem', this.SC.getId()).then((response) => {
          this.SC.setItem(this.getRespData(response))
        }).catch(error => this.createErrorLog(error))
      }
    },

    methods: {
      changeHistoryOfChanges(val) {
        this.isModalHistoryOfChanges = val
      },
    }
  }
</script>

<style scoped lang="scss">

  @import "../../../../../scss/colors";
  @import "../../../../../scss/mixins/mixins";

  .shipping-company-popup {

    &__body {
      display: flex;

      @include for-680 {
        flex-direction: column;
      }
    }

    &__content {
      width: 100%;

      @include from-680 {
        padding-right: 20px;
      }
    }


  }

  .custom-popup__btn {

  }

  .custom-popup__btn-i {
    margin-right: 10px;
    min-width: 122px;
    width: fit-content;
    margin-bottom: 10px;

    &.secondary {
      border-width: 2px;
    }
  }
</style>

import {SHIPPING_COST_DELIVERY_SERVICES} from "../../staticData/staticVariables";

export const shippingCost = {
  computed: {
    isDHLService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.DHL_DELIVERY_SERVICE.id
    },
    isLandmarkService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.LANDMARK_DELIVERY_SERVICE.id
    },
    isTNTService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.TNT_DELIVERY_SERVICE.id
    },
    isFEDEXService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.FEDEX_DELIVERY_SERVICE.id
    },
    isDPDService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.DPD_DELIVERY_SERVICE.id
    },
    isEUProductService(){
      return this.shippingType?.id === SHIPPING_COST_DELIVERY_SERVICES.EU_PRODUCT_DELIVERY_SERVICE.id
    },

  },

  methods: {

    checkShowCost(country) {
      switch (country) {
        case 'ukraine':
          return this.isDHLService || this.isLandmarkService || this.isTNTService || this.isFEDEXService || this.isDPDService || this.isEUProductService
        case 'belarus' :
          return this.isDHLService || this.isTNTService || this.isFEDEXService
        case 'russian' :
          return this.isFEDEXService
        case 'latvia' :
          return this.isDPDService || this.isFEDEXService || this.isEUProductService
        case 'poland' :
          return this.isFEDEXService
        case 'uk' :
          return this.isFEDEXService
      }
    },

    save() {

      let saveType = 'createShippingCost'

      if (!this.SC.firstValidation({
        weight: true,
        cost: this.checkShowCost('ukraine'),
        // costBel: this.checkShowCost('belarus'),
        // costRus: this.checkShowCost('russian'),
        costLat: this.checkShowCost('latvia'),
        costPL: this.checkShowCost('poland'),
        costUk: this.checkShowCost('uk'),
      })) return

      let data = this.SC.prepareSave(
        this.checkShowCost('russian'),
        this.checkShowCost('belarus'),
        this.checkShowCost('latvia'),
        this.checkShowCost('poland'),
        this.checkShowCost('uk'),
      )

      if (this.typePopup === 'edit') {
        saveType = 'updateShippingCost'
        data = {
          data: data,
          id: this.itemId
        }
      }

      this.$store.dispatch(saveType, data).then((response) => {

        if (!this._.has(response, 'data')) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {

            if (this.typePopup === 'edit') {
              this.openNotify('success', 'common_notificationRecordChanged')
            } else {
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            this.$emit('close')
            this.$emit('reload')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

  }

}

import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function ShippingCost() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    type: '',
    storage_id: '',
    weight: '',
    cost: '',
    costBel: '',
    costRus: '',
    costLat: '',
    costPL: '',
    costUk: '',
  }


  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getType = () => {
    return this.data.type
  }
  this.getStorageId = () => {
    return this.data.storage_id
  }
  this.getWeight = () => {
    return this.data.weight
  }
  this.getCost = () => {
    return this.data.cost
  }
  this.getCostBel = () => {
    return this.data.costBel
  }
  this.getCostRus = () => {
    return this.data.costRus
  }
  this.getCostLat = () => {
    return this.data.costLat
  }
  this.getCostPL = () => {
    return this.data.costPL
  }
  this.getCostUk = () => {
    return this.data.costUk
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }

  this.setType = (val) => {
    this.data.type = val
  }
  this.setStorageId = (val) => {
    this.data.storage_id = val
  }
  this.setWeight = (val) => {
    this.data.weight = val
  }
  this.setCost = (val) => {
    this.data.cost = val
  }
  this.setCostBel = (val) => {
    this.data.costBel = val
  }
  this.setCostRus = (val) => {
    this.data.costRus = val
  }
  this.setCostLat = (val) => {
    this.data.costLat = val
  }
  this.setCostPL = (val) => {
    this.data.costPL = val
  }
  this.setCostUk = (val) => {
    this.data.costUk = val
  }


  this.validation = {
    weight: false,
    cost: false,
    costBel: false,
    costRus: false,
    costLat: false,
    costPL: false,
    costUk: false,
  }

  this.validationTranslate = {
    weight: '',
    cost: '',
    costBel: '',
    costRus: '',
    costLat: '',
    costPL: '',
    costUk: '',
  }

  this.validationTxt = {
    weight: false,
    cost: false,
    costBel: false,
    costRus: false,
    costLat: false,
    costPL: false,
    costUk: false,
  }

}


/**
 * Global Setters
 */

ShippingCost.prototype.setItem = function(val) {

  this.setType(val['delivery_service_id'])
  this.setWeight(val['max_weight'])
  this.setCost(val['price'])
  this.setCostBel(val['price_country_group_by'])
  this.setCostRus(val['price_country_group_sng'])
  this.setCostLat(val['price_country_group_latvia'])
  this.setCostPL(val['price_country_group_poland'])
  this.setCostUk(val['price_country_group_uk'])

}



/**
 * Validations
 */

ShippingCost.prototype.firstValidation = function (
  {
    weight,
    cost,
    // costBel,
    // costRus,
    costLat,
    costPL,
    costUk,
  }) {

  let data = this.data

  let validationItems = {
    weight: data.weight,
    cost: data.cost,
    // costBel: data.costBel,
    // costRus: data.costRus,
    costLat: data.costLat,
    costPL: data.costPL,
    costUk: data.costUk,
  }

  let validationOptions = {
    weight: {type: weight ? ['numeric', 'empty'] : []},
    cost: {type: cost ? ['numeric', 'empty'] : []},
    // costBel: {type: costBel ? ['numeric', 'empty'] : []},
    // costRus: {type: costRus ? ['numeric', 'empty'] : []},
    costLat: {type: costLat ? ['numeric', 'empty'] : []},
    costPL: {type: costPL ? ['numeric', 'empty'] : []},
    costUk: {type: costUk ? ['numeric', 'empty'] : []},
  }

  return this.checkValid(validationItems, validationOptions)
}



/**
 * Prepare Data
 */

ShippingCost.prototype.prepareSave = function(rus = false, bel = false, lat = false, pl = false, uk = false) {

  let request = {
    "delivery_service_id": this.getType(),
    "max_weight": this.getWeight(),
    "price": this.getCost(),
  }

  if (this.getStorageId()) {
    request["storage_id"] = this.getStorageId()
  }

  if(rus){
    request["price_country_group_sng"] = this.getCostRus()
  }

  if(bel){
    request["price_country_group_by"] = this.getCostBel()
  }

  if(lat){
    request["price_country_group_latvia"] = this.getCostLat()
  }

  if(pl){
    request["price_country_group_poland"] = this.getCostPL()
  }

  if(uk){
    request["price_country_group_uk"] = this.getCostUk()
  }

  return request

}
